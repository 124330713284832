<template>
  <b-sidebar
    id="app-editor"
    :visible="isAppEditorActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-app-editor-active', val)"
    @hidden="clearForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0" v-if="isNew">
          Add New App
        </h5>
        <h5 class="mb-0" v-else>
          Edit App
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- App Name -->
          <validation-provider
            #default="validationContext"
            name="App Name"
            rules="required"
          >
            <b-form-group
              label="App Name"
              label-for="app-name"
            >
              <b-form-input
                id="app-name"
                v-model="appLocal.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="My Awesome Website"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- App rsiteID -->
          <b-form-group
            label="App ID"
            label-for="app-id"
          >
            <b-form-input
              id="app-id"
              v-model="appLocal.rsiteID"
              disabled
              placeholder="Automatic"
            />
          </b-form-group>
 

          <!--URLs -->
          
          <validation-provider
            #default="validationContext"
            name="urls"
            rules="required"
          >
            <b-form-group
              label="URL(s)"
              label-for="url"
            > 
              <b-form-tags
                v-model="appLocal.url"
                input-id="urls"
                add-button-text="Add"
                add-button-variant="primary"
                :state="getValidationState(validationContext)"
                separator=" "
                placeholder="Add a URL and press ENTER"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>

          
          <div class="switch-inline-spacing">
            <!-- Legacy -->
            <div>
              <b-card-text class="mb-0">
                Legacy
              </b-card-text>
              <b-form-checkbox
                checked="false"
                class="custom-control-primary"
                name="legacy-button"
                switch
                v-model="appLocal.legacy"
              >
              </b-form-checkbox>
            </div>

            <!-- Explicit -->
            <div>
              <b-card-text class="mb-0">
                Explicit
              </b-card-text>
              <b-form-checkbox
                checked="false"
                class="custom-control-primary"
                name="legacy-button"
                switch
                v-model="appLocal.explicit"
              >
              </b-form-checkbox>
            </div>

          <!-- is Active -->
            <div>
              <b-card-text class="mb-0">
                Active
              </b-card-text>
              <b-form-checkbox
                checked="false"
                class="custom-control-primary"
                name="legacy-button"
                switch
                v-model="appLocal.isActive"
              >
              </b-form-checkbox>
            </div>
          </div>

          <b-card
            title="Legacy Settings"
            v-if=appLocal.legacy
            class=""
          >
            <b-row>
              <b-col lg="12">
              <!-- divider -->
                <div class="divider my-2">
                  <div class="divider-text">
                    Classic Settings
                  </div>
                </div>
                <!-- Legacy siteID -->
                <validation-provider
                  #default="validationContext"
                  name="site-id"
                  rules="required"
                >
                  <b-form-group
                    label="Site ID"
                    label-for="site-id"
                  >
                    <b-form-input
                      id="site-id"
                      v-model="appLocal.siteID"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Legacy instance -->
                <validation-provider
                  #default="validationContext"
                  name="instaces-legacy"
                  rules="required"
                >
                  <b-form-group
                    label="Instance"
                    label-for="instaces-legacy"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="appLocal.instance"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="legacyInstancesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="instaces-legacy"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
        
          <!-- Environment -->
          <validation-provider
            v-if=!appLocal.legacy
            #default="validationContext"
            name="Environment"
            rules="required"
          >
            <b-form-group
              label="Environment"
              label-for="env"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="appLocal.instance"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="instancesOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="env"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Observations -->
          <b-form-group
            label="Observations"
            label-for="obesrvations"
          >
            <quill-editor
              id="quil-content"
              v-model="appLocal.obs"
              :options="editorOptions"
              class="border-bottom-0"
            />
            <div
              id="quill-toolbar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              class="mr-2"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-if="isNew"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-if="!isNew"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="danger"
              @click="onDeleteButtonClick"
            >
              Delete
            </b-button>
          </div>

          <b-overlay
            :show="showDeleteConfirmation"
            opacity="1.0"
            no-wrap
          >
            <template #overlay>
              <div
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-delete-confirm-label"
                class="text-center p-3"
              >
                <p><strong id="form-delete-confirm-label">Are you sure?</strong></p>
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="danger"
                    class="mr-3"
                    @click="onCancelDelete"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="success"
                    @click="onConfirmDelete"
                  >
                    OK
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BCardText,
  BFormTags
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { required, alphaNum, digits, uuidv4, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useAppEditorHandler from './useAppEditorHandler'

export default {
  components: {
    // BSV
    BSidebar,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardText,
    BFormTags,
    // 3rd party packages
    vSelect,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppEditorActive',
    event: 'update:is-app-editor-active',
  },
  props: {
    isAppEditorActive: {
      type: Boolean,
      required: true
    },
    app: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      uuidv4
    }
  },
  setup(props, { emit }) {

    const {
      appLocal,
      resetAppLocal,
      onSubmit,
      onConfirmDelete,
      onCancelDelete,

      // UI
      legacyInstancesOptions,
      instancesOptions,
      onDeleteButtonClick,
      showDeleteConfirmation
    } = useAppEditorHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm
    } = formValidation(resetAppLocal)

    const editorOptions = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Observations',
    }
  
    return {
      appLocal,
      onSubmit,
      legacyInstancesOptions,
      instancesOptions,
      onDeleteButtonClick,
      onConfirmDelete,
      onCancelDelete,
      
      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,

      // UI
      editorOptions,
      showDeleteConfirmation
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

#add-new-app-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.switch-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 1.5rem;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.dark-layout {
  div ::v-deep .b-sidebar-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
