<template>
  <div>
    <app-editor
      v-model="isAppEditorActive"
      :app="app"
      :is-new="isNew"
      @remove-app="removeApp"
      @add-app="addApp"
      @update-app="updateApp"
    />

    <apps-table
      :isbusy="AppsTableIsBusy"
      :items="apps"
      :resolveAppIsActiveVariant="resolveAppIsActiveVariant"
      :resolveAppIsLegacyVariant="resolveAppIsLegacyVariant"
      @edit-app="handleAppClick"
      @add-app="handleNewAppClick"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import store from '@/store'
import { ref, onMounted, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppEditor from './apps/AppEditor.vue'
import AppsTable from './apps/AppsTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    // App
    AppsTable,
    AppEditor
  },
  setup() {
    const toast = useToast()
    const isAppEditorActive = ref(false)
    const isNew = ref(false)
    const apps = ref([])
    const app = ref({})
    const AppsTableIsBusy = ref(false)

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      fetchApps()
    })

    // Reset IsNew when the Sidebar is closed/canceled
    watch(isAppEditorActive, (open) => {
      if (!open) {
        isNew.value = false
      }
    })

    // Get Apps List
    const fetchApps = async () => {
      
      if (store.getters['apps/totalApps'] > 0) {
        // Get Apps from cache
        apps.value = store.getters['apps/apps']
        return true
      }
 
      try{
        AppsTableIsBusy.value = true
        await store.dispatch('apps/fetchApps')
        apps.value = store.getters['apps/apps']
        AppsTableIsBusy.value = false
      } catch (error) {
        displayError('Error: fetching apps list failed')
        AppsTableIsBusy.value = false
      }
    }

    // Add an App
    const addApp = async val => {
      try{
        AppsTableIsBusy.value = true
        await store.dispatch('apps/addApp', val)
        fetchApps()
        AppsTableIsBusy.value = false
      } catch (error) {
        displayError('Error: Adding app failed')
        AppsTableIsBusy.value = false
      }
    }

    // Update App
    const updateApp = async val => {
      // Check changes
      if (JSON.stringify(val) === JSON.stringify(app.value)) {
        console.log('No Changes Detected')
        return
      }

      // Detect PPAS changes
      val.ppasChanges = val.title !== app.value.title ||
        JSON.stringify(val.url) !== JSON.stringify(app.value.url)
      
      // Detect trackerURL changes
      val.trackerURLChanges = JSON.stringify(val.trackerURL) !== JSON.stringify(app.value.trackerURL)
      
      try {
        AppsTableIsBusy.value = true
        await store.dispatch('apps/updateApp', val)
        fetchApps()
        AppsTableIsBusy.value = false
      } catch (error) {
        displayError('Error: Updating App failed')
        AppsTableIsBusy.value = false
      }
    }

    // Remove App
    const removeApp = async val => {
      try{
        AppsTableIsBusy.value = true
        await store.dispatch('apps/removeApp', val)
        fetchApps()
        AppsTableIsBusy.value = false
      } catch (error) {
        displayError('Error: Removing App failed')
        AppsTableIsBusy.value = false
      }
    }

    const handleAppClick = appData => {
      // console.log('Edit App call')
      app.value = appData
      isNew.value = false
      isAppEditorActive.value = true
    }

    const handleNewAppClick = () => {
      // console.log('New App call')
      isNew.value = true
      app.value = {}
      isAppEditorActive.value = true
    }

    const hidden = () => {
      console.log('Sidebar hidden')
    }

    const resolveAppIsActiveVariant = isActive => {
      if (isActive === 'true') return 'success'
      if (isActive === 'false') return 'secondary'
      return 'primary'
    }

    const resolveAppIsLegacyVariant = isActive => {
      if (isActive === 'true') return 'success'
      if (isActive === 'false') return 'secondary'
      return 'primary'
    }

    // ------------------------------------------------
    // (UI) displayError
    // ------------------------------------------------
    const displayError = title => {
      toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    }

    return {
      apps,
      app,
      removeApp,
      addApp,
      updateApp,

      // Editor
      isAppEditorActive,
      AppsTableIsBusy,
      isNew,
      handleAppClick,
      handleNewAppClick,
      resolveAppIsActiveVariant,
      resolveAppIsLegacyVariant
    }
  }
}
</script>
