import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useAppEditorHandler(props, emit) {
  // ------------------------------------------------
  // appLocal
  // ------------------------------------------------
  const blankApp = {
    siteID: '',
    instance: '',
    title: '',
    rsiteID: '',
    sitePath: [],
    url: [],
    trackerURL: '',
    obs: '',
    explicit: false,
    isActive: true,
    legacy: false
  }

  const appLocal = ref(JSON.parse(JSON.stringify(props.app.value)))
  
  const clearApplocal = () => {
    appLocal.value = JSON.parse(JSON.stringify(blankApp))
    // console.log('Blank App')
  }

  const showDeleteConfirmation = ref(false)
  
  const resetAppLocal = () => {
    appLocal.value = JSON.parse(JSON.stringify(props.app.value))
  }

  watch(props.app, () => resetAppLocal())

  watch(props.isNew, (val) => {
    if (val) {
      clearApplocal()
    }
  })

  const onSubmit = () => {
    const appData = JSON.parse(JSON.stringify(appLocal))

    // Re-apply the trackerURL
    appData.value.trackerURL = trackerURLS.find(i => i.label === appData.value.instance).value

    // Process sitePath(s)
    appData.value.sitePath = []
    for (let url of appData.value.url) {
      appData.value.sitePath.push(url.replace(/^(https?:|)\/\//, ''))
    }

    if (props.isNew.value) {
      resetAppLocal()
      emit('add-app', appData.value)
    } else {
      resetAppLocal()
      emit('update-app', appData.value)
    }
    
    emit('update:is-app-editor-active', false)
  }

  const onDeleteButtonClick = () => {
    //Show confirmation
    showDeleteConfirmation.value = true
  }

  const onConfirmDelete = () => {
    deleteApp()
    showDeleteConfirmation.value = false
  }

  const onCancelDelete = () => {
    showDeleteConfirmation.value = false
  }

  const deleteApp = () => {
    const appData = JSON.parse(JSON.stringify(appLocal))
    emit('remove-app', appData.value)
    emit('update:is-app-editor-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  
  const statusOptions = [
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' },
  ]

  const legacyInstancesOptions = [
    { label: 'EC', value: 'ec.europa.eu' },
    { label: 'Europa', value: 'europa.eu' }
  ]

  const instancesOptions = [
    { label: 'Production', value: 'ec' },
    { label: 'Testing', value: 'testing' }
  ]

  const trackerURLS = [
    { label: 'ec', value: process.env.VUE_APP_PPAS_PROD_ENDPOINT },
    { label: 'ec.europa.eu', value: process.env.VUE_APP_PPAS_PROD_ENDPOINT },
    { label: 'europa.eu', value: process.env.VUE_APP_PPAS_PROD_ENDPOINT },
    { label: 'testing', value: process.env.VUE_APP_PPAS_TEST_ENDPOINT }
  ]

  return {
    appLocal,
    resetAppLocal,
    clearApplocal,

    // UI
    onSubmit,
    onDeleteButtonClick,
    onConfirmDelete,
    onCancelDelete,
    statusOptions,
    legacyInstancesOptions,
    instancesOptions,
    showDeleteConfirmation
  }
}
