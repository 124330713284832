<template>
  <!-- Table Container Card -->
  <b-card
    no-body
    class="mb-0"
  > 
    <div class="m-2">
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            id="perPageSelect"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter"
              class="d-inline-block mr-1"
              placeholder="Search..."
              id="filterInput"
            />
            <b-button
              variant="primary"
              @click="$emit('add-app')"
            >
              <span class="text-nowrap">Add App</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      class="position-relative"
      id="table-apps-list"
      ref="tableAppsList"
      primary-key="rsiteID"
      hover
      responsive
      no-select-on-click
      show-empty
      empty-text="No matching apps found"
      :busy.sync="isbusy"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-clicked="onRowClick"
      
    > 
      <template v-slot:table-busy>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            variant="primary"
          />
        </div>
      </template>

      <!-- Column: legacy -->
      <template #cell(url)="data">
        
        <b-badge
          v-for="(_url, index) of data.item.url"
          :key="index"
          variant="primary"
        >
          {{ _url }}
        </b-badge>
      </template>

      <!-- Column: legacy -->
      <template #cell(legacy)="data">
        <b-badge
          pill
          :variant="`light-${resolveAppIsLegacyVariant(data.item.legacy)}`"
          class="text-capitalize"
        >
          {{ data.item.legacy }}
        </b-badge>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BSpinner,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BSpinner,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
    vSelect
  },
  props: {
    isbusy: {
      type: Boolean
    },
    items: {
      type: Array
    },
    resolveAppIsActiveVariant: {
      type: Function
    },
    resolveAppIsLegacyVariant: {
      type: Function
    }
  },
  data: () => ({
    perPage: 15,
    pageOptions: [15, 25, 50, 100],
    totalRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    modes: ['single'],
    filter: null,
    filterOn: [],
    fields: [
      {
        key: 'title',
        label: 'Title',
        sortable: true
      },
      { key: 'url' },
      { key: 'siteID', sortable: true },
      { key: 'instance', label: 'Instance', sortable: true },
      { key: 'legacy', label: 'Legacy', sortable: true }
    ]
  }),
  watch: {
    items() {
      // console.log('Items Changed')
      this.totalRows = this.items.length
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowClick(appData) {
      this.$emit('edit-app', appData)
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
